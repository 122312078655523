import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'
import router from './router';

const store = new createStore({
  state : {
      remember: 0,
      login_username : '',
      token : '',
      menu : [],
      nombre : '',
      tipo : '',
      saludo : '',
      codigo_uonline : '',
      codigo_cliente : '',
      is_logged : 0,
      is_registered : 0,
  },
  mutations: {
    setNombre(state, value){
      this.state.nombre = value;
      this.state.saludo = "Hola, " + value.split(" ")[0].toLowerCase();
    },
    setCodigoUonline(state, value){ state.codigo_uonline = value; },
    setRegistered(state, value){ state.is_registered = value; },
    setCodigoCliente(state, value){ state.codigo_cliente = value; },
    setRemember( state, value = 0 ){ state.remember = value; },
    setUsername( state, value = "" ){ state.login_username = value; },
    setToken(state, value){
      state.token = value;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + value;
    },
    setTipo(state, value){ state.tipo = value; },
    setMenu(state, value){ state.menu = value; },
    setLogged(state, value){
      if(value == 1){
        state.is_logged = value;
      }else{
        state.tipo = "";
        state.menu = "";
        state.token = "";
        state.nombre = "";
        state.saludo = "";
        state.codigo_uonline = "";
        state.codigo_cliente = "";
        state.is_logged = 0;
        axios.defaults.headers.common['Authorization'] = '';
        router.push("/");
      }
    }
  },
  plugins : [ 
    new VuexPersistence({
      storage: window.localStorage 
    }).plugin
  ]
})

export default store