import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/layout/layout.scss';

import { createApp, reactive } from 'vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import Badge from 'primevue/badge';
import MultiSelect from 'primevue/multiselect';
import ConfirmDialog from 'primevue/confirmdialog';
import OverlayPanel from 'primevue/overlaypanel';
import Chip from 'primevue/chip'
import ProgressSpinner from 'primevue/progressspinner'
import PrimeVue from 'primevue/config';
import BadgeDirective from 'primevue/badgedirective';
import SplitButton from 'primevue/splitbutton';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import Ripple from 'primevue/ripple';
import Tag from 'primevue/tag';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import InlineMessage from 'primevue/inlinemessage';
import Carousel from 'primevue/carousel';
import Image from 'primevue/image'
import Fieldset from 'primevue/fieldset';

const app = createApp(App)

app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' })

app.config.globalProperties.$format = {
  usd(number){
    let f = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return f.format(number);
  },
  cut(texto, limite){
    if(texto == undefined || texto == null) return '';
    return (texto.length > limite) ? texto.substr(0,limite).trim()+"..." : texto;
  },
}

app.config.globalProperties.$date = {
  anios(){
    let d = new Date
  let a = d.getFullYear()
    let r = [];
    for(let i = a ; i > a-100; i--) { r.push({value : i.toString()}) }
    return r;
  },
  meses(){
    return [
      { code : 1, name : 'Enero' },
      { code : 2, name : 'Febrero' },
      { code : 3, name : 'Marzo' },
      { code : 4, name : 'Abril' },
      { code : 5, name : 'Mayo' },
      { code : 6, name : 'Junio' },
      { code : 7, name : 'Julio' },
      { code : 8, name : 'Agosto' },
      { code : 9, name : 'Septiembre' },
      { code : 10, name : 'Octubre' },
      { code : 11, name : 'Noviembre' },
      { code : 12, name : 'Diciembre' },
    ];
  },
  dia_mes(mes, anio){
    let r = [];
    if(mes == '' || anio == '') return [];
    let p = new Date(anio, mes, 0).getDate();
    for(let i = 1; i <= p; i++){ r.push({value : i.toString()}); }
    return r;
  },
}

router.beforeEach(function(to, from, next) { 
  window.scrollTo(0, 0); next(); 
});

axios.defaults.baseURL = process.env.VUE_APP_URLAPI;
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token

axios.interceptors.response.use(function (response) { return response; },
  function (error) {
    if(error.response.status == 401) store.commit("setLogged",0);
    return Promise.reject(error);
  }
);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);

app.component('Badge', Badge);
app.component('MultiSelect', MultiSelect);
app.component('InlineMessage', InlineMessage);
app.component('ConfirmDialog', ConfirmDialog);
app.component('OverlayPanel', OverlayPanel);
app.component('Chip', Chip);
app.component('ProgressSpinner', ProgressSpinner);
app.component('SplitButton', SplitButton);
app.component('Button', Button);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('Column', Column);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('Panel', Panel);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('SelectButton', SelectButton);
app.component('Tag', Tag);
app.component('Toast', Toast);
app.component('Carousel', Carousel);
app.component('Image', Image);
app.component('Fieldset', Fieldset);



app.use(PrimeVue, { ripple: true })
app.use(VueAxios, axios)
app.use(ConfirmationService)
app.use(ToastService)
app.use(router)
app.use(store)

app.mount('#app');