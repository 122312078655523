<template>
	<Menubar :model="items" :breakpoint="800" style="background-color: #263680;">
		<template #start>
			<router-link v-if="this.$store.state.is_logged == 1" :to="'/cursos'" >
				<div class="p-d-flex p-ai-center">
					<img src="assets/layout/images/logo_navbar.png" style="height:50px;" />
				</div>
			</router-link>
			<div v-else class="p-d-flex p-ai-center">
				<img src="assets/layout/images/logo_navbar.png" style="height:50px;" />
			</div>
		</template>
		<template #item="{item}" class="p-menubar-root-list" role="menubar">
			<router-link :to="item.to" class="p-menuitem-link">
				<i class="p-menuitem-icon" :class="item.icon"></i>
				<span class="p-menuitem-text" >{{item.label}}</span>
			</router-link>
		</template>
		<template #end v-if="this.$store.state.is_logged == 1" class="">
			<Button icon="pi pi-user" @click="perfil" :label="this.$store.state.saludo" iconPos="right" class="p-text-capitalize p-button-rounded p-button-text p-text-white"></Button>
			<Button icon="pi pi-sign-out" @click="logout" v-tooltip.bottom="'Salir'" class="p-button-rounded p-button-text p-text-white"></Button>
		</template>
	</Menubar>
	<div v-if="this.$store.state.is_logged == 1" class="menu-movil">
		<ul>
			<li>
				<router-link :to="'/cursos'">
					<i class="p-menuitem-icon pi pi-bookmark"></i>
					<span class="p-menuitem-text" >Cursos</span>
				</router-link>
			</li>
			<li>
				<router-link :to="'/actividad'">
					<i class="p-menuitem-icon pi pi-check-circle"></i>
					<span class="p-menuitem-text" >Actividad</span>
				</router-link>
			</li>
			<li>
				<router-link :to="'/pagos'">
					<i class="p-menuitem-icon pi pi-dollar"></i>
					<span class="p-menuitem-text" >Pagos</span>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		methods: {
			onMenuToggle(event) {
					this.$emit('menu-toggle', event);
			},
			perfil(){
				this.$router.push("/perfil");
			},
			logout(){
				this.$store.commit("setLogged", 0);
			},
		},
		props : {
			items : {
				Type : Object
			}
		},
	}
</script>

<style>
	.router-link-active{
		background: #434c9a !important;
	}
	.layout-wrapper .layout-static{
		display: flex !important;
		justify-content: space-between !important;
	}

	.p-menubar-start{
		margin-right:10px;
	}

	.p-menuitem-text{
		text-transform: capitalize !important;
	}

	.p-text-capitalize span.p-button-label{
		text-transform: capitalize !important;
	}

	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text  {
		color :white !important;
	}
	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon  {
		color :white !important;
	}
	@media (max-width: 960px) {
		.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text  {
			color :black !important;
		}
		.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon  {
			color :black !important;
		}
	}

	.menu-movil{
		display: none !important;
		background-color: white;
		width: 100% !important;
		height: 60px;
		position: fixed;
		z-index: 1100;
		bottom: 0 !important;
		box-shadow:  0px -3px 7px -5px rgba(0,0,0,0.61);
	}

	@media screen and (max-width: 960px) {
		.layout-footer{
			padding-bottom: 70px !important;
		}
		.p-menubar-button{
			display: none !important;
		}
		.menu-movil > ul > li{
			list-style: none;
		}
		.menu-movil{
			display: flex !important;
			justify-content: center;
			align-items: center;
			width: 100%;
		}
		.menu-movil > ul {
			display: flex !important;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			height: 100%;
			width: 100%;
			margin: 0;
			padding: 0;
			cursor: pointer;
		}
		.menu-movil > ul  > li {
			width: 100%;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.menu-movil > ul  > li > a {
			width: 100%;
			height: 60px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #424242;
		}

		.menu-movil > ul  > li > a > i {
			font-size: 14pt;
		}

		.menu-movil > ul  > li > a.router-link-active {
			background-color: #E5E5E5 !important;
		}

	}

	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
		background: #34408c;
	}
	.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
		background: #34408c;
	}
	.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: none;
	}
	.p-menubar-button{
		color:white !important;
	}
	.p-menubar{
		border: none !important;
		border-radius: 0 !important;
	}
</style>
