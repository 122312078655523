<template>
	<div class="layout-footer" style="background:#EDF0F5; ">
		<center>
			<span class="footer-text" style="margin-left: 5px">Copyright © {{anio}} Universidad Gerardo Barrios</span>
		</center>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		computed : {
			anio : function(){
				let a = new Date().getFullYear();
				return a;
			}
		}
	}
</script>

<style scoped>

</style>