import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/register',
    name: 'register',
    component: () => import('./pages/Register.vue'),
  },
  {
    path: '/',
    name: 'login',
    component: () => import('./pages/Login.vue'),
  },
  {
    path: '/cursos',
    name: 'cursos',
    component: () => import('./pages/Cursos/Cursos.vue'),
  },
  {
    path: '/pagos',
    name: 'pagos',
    component: () => import('./pages/Pagos.vue'),
  },
  {
    path: '/actividad',
    name: 'actividad',
    component: () => import('./pages/Actividad.vue'),
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('./pages/Perfil.vue'),
  },
  {
    path: '/confirmacion',
    name: 'confirmacion',
    component: () => import('./pages/Confirmacion.vue'),
  },
  {
    path: '/recuperacion',
    name: 'recuperacion',
    component: () => import('./pages/Recuperacion/Recuperacion.vue'),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
