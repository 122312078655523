<template>
	<div :class="containerClass" @click="onWrapperClick">
		<AppTopBar :items="this.$store.state.menu" @menu-toggle="onMenuToggle" />
        <div class="layout-main"> <router-view /> </div>
		<AppFooter />
	</div>
</template>

<script>
import AppTopBar from './utilities/AppTopbar.vue';
import AppFooter from './utilities/AppFooter.vue';

export default {
    data() {
      return {
        layoutMode: 'static',
        layoutColorMode: 'dark',
        staticMenuInactive: false,
        overlayMenuActive: false,
        mobileMenuActive: false,
        menu : [],
      }
    },
    mounted(){
      this.menu = this.$store.state.menu;
    },
    watch: {
      $route() {
        this.menuActive = false;
        this.$toast.removeAllGroups();
      },
    },
    methods: {
      onWrapperClick() {
        if (!this.menuClick) {
          this.overlayMenuActive = false;
          this.mobileMenuActive = false;
        }
        this.menuClick = false;
      },
      onMenuToggle() {
        this.menuClick = true;
        if (this.isDesktop()) {
          if (this.layoutMode === 'overlay') {
            if(this.mobileMenuActive === true) {
              this.overlayMenuActive = true;
            }
            this.overlayMenuActive = !this.overlayMenuActive;
            this.mobileMenuActive = false;
          } else if (this.layoutMode === 'static') {
            this.staticMenuInactive = !this.staticMenuInactive;
          }
        } else {
          this.mobileMenuActive = !this.mobileMenuActive;
        }
        event.preventDefault();
      },
      onSidebarClick() {
        this.menuClick = true;
      },
      onMenuItemClick(event) {
        if (event.item && !event.item.items) {
          this.overlayMenuActive = false;
          this.mobileMenuActive = false;
        }
      },
      onLayoutChange(layoutMode) {
        this.layoutMode = layoutMode;
      },
      onLayoutColorChange(layoutColorMode) {
        this.layoutColorMode = layoutColorMode;
      },
      addClass(element, className) {
        if (element.classList)
          element.classList.add(className);
        else
          element.className += ' ' + className;
      },
      removeClass(element, className) {
        if (element.classList)
          element.classList.remove(className);
        else
          element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      },
      isDesktop() {
        return window.innerWidth > 1024;
      },
      isSidebarVisible() {
        if (this.isDesktop()) {
          if (this.layoutMode === 'static')
            return !this.staticMenuInactive;
          else if (this.layoutMode === 'overlay')
            return this.overlayMenuActive;
          else
            return true;
        }
        else 
        {
          return true;
        }
      },
    },
    computed: {
      containerClass() {
        return ['layout-wrapper', {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$appState.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false
        }];
      },
    },
    components: {
      'AppTopBar': AppTopBar,
      'AppFooter': AppFooter,
    }
}
</script>
